// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Button, Card, Carousel, Hero, Icon, Table, text } from '@toggl/ui'
import { colors, fonts, media, themes } from '@toggl/style'

import IndexLayout from '../layouts'
import LayoutGlobal from '../components/LayoutGlobal'

import heroBg from '../../static/images/uploads/homepage-hero-bg.jpg'
import videoSrc from '../../static/images/uploads/bg-video.mp4'
import { ThemeProvider } from 'emotion-theming'
import planImg from '../../static/images/uploads/product_plan.png'
import trackImg from '../../static/images/uploads/product_track.png'
import hireImg from '../../static/images/uploads/product_hire.png'

const tabs = [
  {
    id: 'terms_of_servie',
    title: 'Terms of Service',
    sections: [
      {
        title: '1.0 - Introduction',
        content: (
          <>
            These terms of service describe the rights and obligations of the
            User and the Supplier in connection with the Service and form an
            essential part of a binding contract between them (capitalised words
            used in this introduction, including the words "User", "Supplier"
            and "Service", are defined below in section 1.1). If you are reading
            this text, there is a good chance that you may be about to become,
            or perhaps already are, a User. So please do consider these terms
            carefully as they are likely to affect your rights and obligations.
            <br />
            <br />
            If you wish to enjoy the Service as a consumer, i.e., a natural
            person (an individual) acting for purposes outside his or her trade,
            business, craft or profession, then please pay particular attention
            to section 3.1 and articles 4 and 20. You are also advised that,
            before proceeding to create an account, you should study the
            documentation pertaining to the Service (links to which are provided
            below under the definition of "Documentation") and consider
            carefully whether the Service is likely to meet your requirements,
            for a consumer's right to withdraw from this contract and the right
            to withdraw from subscriptions are excluded under article 4.
            <br />
            <br />
            You should bear in mind that, even though certain subscriptions may
            be available free of charge, the more feature-rich Service Plans are
            always paid. This means that placing an order for a Service Plan
            often entails an obligation to pay. Please consult the Documentation
            prior to ordering anything and, when you do place an order, pay
            attention to the information displayed upon each step of the
            procedure to be certain that what you are ordering is indeed what
            you desire and that the terms presented are acceptable.
            <br />
            <br />
            If you are not of legal age (which is likely to be the case if you
            are under 18) or otherwise do not possess full active legal
            capacity, then the Service is not for you (see section 3.2).
            <br />
            <br />
            If you are associated with a patent assertion entity (also known as
            a patent troll), please see section 3.3.
            <br />
            <br />
            Should you find anything in these Terms that you do not agree with,
            please do not use any of the Features, close your User Account and
            remove all Software and other items forming part of the Service from
            your systems, devices, storage media and repositories.
            <br />
            <br />
            Regardless of your purposes and whether you agree with the Terms,
            please consider the Supplier's Privacy Policy
            (https://toggl.com/legal/privacy). This document describes how, when
            and why the Supplier collects information about individuals
            (including but not limited to customers), how and for what purposes
            these personal data are processed, who processes them and what
            rights the individuals have in connection with the data concerning
            them.
          </>
        ),
      },
      {
        title: '1.1 Interpretation',
        content: `These terms of service describe the rights and obligations of the User and the Supplier in connection with the Service and form an essential part of a binding contract between them (capitalised words used in this introduction, including the words "User", "Supplier" and "Service", are defined below in section 1.1). If you are reading this text, there is a good chance that you may be about to become, or perhaps already are, a User. So please do consider these terms carefully as they are likely to affect your rights and obligations.

If you wish to enjoy the Service as a consumer, i.e., a natural person (an individual) acting for purposes outside his or her trade, business, craft or profession, then please pay particular attention to section 3.1 and articles 4 and 20. You are also advised that, before proceeding to create an account, you should study the documentation pertaining to the Service (links to which are provided below under the definition of "Documentation") and consider carefully whether the Service is likely to meet your requirements, for a consumer's right to withdraw from this contract and the right to withdraw from subscriptions are excluded under article 4.

You should bear in mind that, even though certain subscriptions may be available free of charge, the more feature-rich Service Plans are always paid. This means that placing an order for a Service Plan often entails an obligation to pay. Please consult the Documentation prior to ordering anything and, when you do place an order, pay attention to the information displayed upon each step of the procedure to be certain that what you are ordering is indeed what you desire and that the terms presented are acceptable.

If you are not of legal age (which is likely to be the case if you are under 18) or otherwise do not possess full active legal capacity, then the Service is not for you (see section 3.2).

If you are associated with a patent assertion entity (also known as a patent troll), please see section 3.3.

Should you find anything in these Terms that you do not agree with, please do not use any of the Features, close your User Account and remove all Software and other items forming part of the Service from your systems, devices, storage media and repositories.

Regardless of your purposes and whether you agree with the Terms, please consider the Supplier's Privacy Policy (https://toggl.com/legal/privacy). This document describes how, when and why the Supplier collects information about individuals (including but not limited to customers), how and for what purposes these personal data are processed, who processes them and what rights the individuals have in connection with the data concerning them.`,
      },
    ],
  },
  {
    id: 'privacy_policy',
    title: 'Privacy Policy',
    sections: [
      {
        title: '2',
        content: <div>{'Terms of Service'}</div>,
      },
    ],
  },
  {
    id: 'data_protection',
    title: 'Data Protection',
    sections: [
      {
        title: '2',
        content: <div>{'Terms of Service'}</div>,
      },
    ],
  },
  {
    id: 'subprocessor_list',
    title: 'Sub-processor List',
    sections: [
      {
        title: '2',
        content: <div>{'Terms of Service'}</div>,
      },
    ],
  },
  {
    id: 'example_page',
    title: 'Example Page',
    sections: [
      {
        title: '2',
        content: <div>{'Terms of Service'}</div>,
      },
    ],
  },
]

const FeaturesPage = () => {
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
        {children}
        <HeroContentWrapper>
          <Hero.ContentWrapper>
            <Hero.Title centered>Legal pages</Hero.Title>
          </Hero.ContentWrapper>
        </HeroContentWrapper>
      </Hero.Wrapper>
    )
  }

  return (
    <IndexLayout>
      <LayoutGlobal Hero={HeroComponent}>
        <ThemeProvider theme={themes.global}>
          <Table.LegalContent tabs={tabs} />

          <ProductsSection>
            <ProductsSectionBgColor />
            <ProductsSectionHeader>
              Toggl's <Rotalic>Products</Rotalic>
            </ProductsSectionHeader>

            <Carousel.DotMobile>
              <ProductsSectionProduct>
                <ThemeProvider theme={themes.plan}>
                  <Card.ProductAlternate
                    Logo={Icon.Plan}
                    title={'Beautifully simple project planning'}
                    image={planImg}
                    Cta={() => (
                      <a href={'/plan/'} target="_blank" rel="noreferrer">
                        <Button.Primary>Discover Plan</Button.Primary>
                      </a>
                    )}
                  />
                </ThemeProvider>
              </ProductsSectionProduct>

              <ThemeProvider theme={themes.track}>
                <Card.ProductAlternate
                  Logo={Icon.Track}
                  title={'Effortless time tracking'}
                  image={trackImg}
                  Cta={() => (
                    <a href={'/track/'} target="_blank" rel="noreferrer">
                      <Button.Primary>Discover Track</Button.Primary>
                    </a>
                  )}
                />
              </ThemeProvider>

              <ThemeProvider theme={themes.hire}>
                <Card.ProductAlternate
                  Logo={Icon.Hire}
                  title={'Intelligent candidate screening'}
                  image={hireImg}
                  Cta={() => (
                    <a href={'/hire/'} target="_blank" rel="noreferrer">
                      <Button.Primary>Discover Track</Button.Primary>
                    </a>
                  )}
                />
              </ThemeProvider>
            </Carousel.DotMobile>
          </ProductsSection>
        </ThemeProvider>
      </LayoutGlobal>
    </IndexLayout>
  )
}

const H1 = styled.h1`
  ${text.heading1};
  padding-bottom: 20px;
  z-index: 1;
  color: ${(p) => p.theme.primary};
`

const ProductsSectionBgColor = styled.span`
  border-radius: 50%;
  width: 480px;
  height: 480px;
  background: ${colors.darkPurple};
  position: absolute;

  ${media.mq[1]} {
    width: 740px;
    height: 740px;
  }
`

const ProductsSectionProduct = styled.div`
  margin-left: 13px;
`

const ProductsSectionHeader = styled(H1)`
  width: 165px;
  padding-top: 53px;
  padding-bottom: 27px;
  text-align: center;

  ${media.mq[1]} {
    width: 100%;
  }
`

const ProductsSection = styled(Card.ProductSection)`
  padding-top: 16px;
  position: relative;
  overflow: hidden;

  & > div {
    z-index: 1;
  }

  ${media.mq[1]} {
    height: 740px;
    padding-top: 0;
    margin: 129px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

const HeroContentWrapper = styled.div`
  margin-top: 10px;

  ${media.mq[1]} {
    padding-top: 115px;
    padding-bottom: 88px;
  }
`

const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};

  ${media.mq[1]} {
    color: ${(p) => p.theme.primary};
  }
`

export default FeaturesPage
